<template>
  <div class="sp_main" v-if="show">
    <div class="sp_body">
      <div class="close" @click="close">×</div>
      <div class="sp_item" v-for="(item,index) in urls" :key="index">
        <div :id="'video'+index"></div>
        <div class="sdwd_box flex_start" v-if="item.show">
          <div class="sd_word">湿度：{{item.sd}}%</div>
          <div class="sd_word">温度：{{item.wd}}℃</div>
        </div>
      </div>
      <div class="btn">
        <el-button @click="previous" :disabled="previousFlg">上一页</el-button>
        <span style="margin-left: 50px;color: white;">{{i}}/{{total}}</span>
        <el-button style="margin-left: 50px;" @click="next" :disabled="nextFlg">下一页</el-button>
      </div>
    </div>
  </div>

  <!-- <div class="ezuikit_box flex_align flex_center_align flex_center" v-if="show">
    <div class="video_box">
      <div id="video-container" style="width:600px;height:600px;" class="ezui_video "></div>
      <div class="close" @click="close">×</div>
    </div>
  </div> -->
</template>

<script>
import { getTokenVideo, selectCameraByRegion } from '@/api/api.js'
import ElementUI from 'element-ui'
import EZUIKit from 'ezuikit-js'
var player1 = null
var player2 = null
var player3 = null
var player4 = null
var player = null
var start = 0
var page = 15
export default {
  name: 'EZUIKit',
  data() {
    return {
      show: false,
      urls: [],
      tableData: [],
      accessToken: '',
      pingfangziaccessToke: '',
      timeId: null,
      baseform: {},
      dtype: '',
      id: '',
      nextFlg: false,
      previousFlg: true,
      i: 1,
      total : 4
    }
  },
  methods: {
    statusMap(row) {
      if (row.status == 1) {
        return '正常'
      } else {
        return '异常'
      }
    },
    randomNumsd(minNum, maxNum) {
      switch (arguments.length) {
        case 1:
          return parseInt(Math.random() * minNum + 1, 10)
          break
        case 2:
          return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
          break
        default:
          return 0
          break
      }
    },
    randomNumwd(maxNum, minNum, decimalNum) {
      var max = 0,
        min = 0
      minNum <= maxNum ? ((min = minNum), (max = maxNum)) : ((min = maxNum), (max = minNum))
      switch (arguments.length) {
        case 1:
          return Math.floor(Math.random() * (max + 1))
          break
        case 2:
          return Math.floor(Math.random() * (max - min + 1) + min)
          break
        case 3:
          return (Math.random() * (max - min) + min).toFixed(decimalNum)
          break
        default:
          return Math.random()
          break
      }
    },
    setwdsd() {
      this.timeId = setInterval(() => {
        this.urls.forEach((item, index) => {
          if (index == 0 || index == 1) {
            item.wd = this.randomNumwd(25, 27, 2)
            item.sd = this.randomNumsd(30, 32)
          } else {
            item.wd = this.randomNumwd(22, 24, 2)
            item.sd = this.randomNumsd(75, 76)
          }
        })
      }, 4000)
    },
    previous(){
      this.i -= 1
      if (page == 30){
        this.previousFlg = true
      }else{
        this.previousFlg = false
        this.nextFlg = false
      }
      if (player) {
        player.stop()
        player = null
      }
      this.show = false
      start = start -15
      page = page -15
      this.nextInitData(start,page)
    },
    next(){
      this.i += 1
      if (page == 45){
        this.nextFlg = true
      }else{
        this.nextFlg = false
        this.previousFlg = false
      }
      if (player) {
        player.stop()
        player = null
      }
      this.show = false
      start = page
      page += 15
      this.nextInitData(start,page)
    },

    nextInitData(start, page) {
      selectCameraByRegion({ managementId: this.id,pingfangziaccessToke: this.pingfangziaccessToke }).then(res => {
        this.show = true
        this.urls = res.model
        this.$nextTick(() => {
          res.model.forEach((item, index) => {
            if (start <= index && index < page){
              setTimeout(() => {
                let id = 'video' + index
                this.initEzuik(item.cameraUrl, id, index + 1)
              }, 0)
            }
          })
        })
      })
    },


    initData(data) {
      if (data?.type && data.type === 'videoType') {
        this.dtype = data.type
        this.baseform = data.name
        this.id = data.id
        selectCameraByRegion({ managementId: data.id,pingfangziaccessToke: this.pingfangziaccessToke }).then(res => {
          this.show = true
          this.urls = res.model
          this.$nextTick(() => {
            res.model.forEach((item, index) => {
              if (index < page){
                setTimeout(() => {
                  let id = 'video' + index
                  this.initEzuik(item.cameraUrl, id, index + 1)
                }, 0)
              }
            })
          })
        })
      } else {
        this.show = true
        this.urls = data.urls
        this.$nextTick(() => {
          this.urls.forEach((item, index) => {
            setTimeout(() => {
              let id = 'video' + index
              this.initEzuik(item.url, id, index + 1)
            }, 0)
          })
          this.setwdsd()
        })
      }
    },

    close() {
      if (player) {
        player.stop()
        player = null
      }
      this.show = false
    },

    // 获取Token
    getTokenpingfangzi() {
      let datapfz = {
        appKey: 'a145fefba06a4bfb8ac283db040085a1',
        appSecret: '05e998d7e5044c346bedbb501c8747a7'
      }
      getTokenVideo(datapfz).then(res => {
        if (res.code == 200) {
          this.pingfangziaccessToke = res.data.accessToken
        }
      })
    },

    getTokenVideo() {
      let data = {
        appKey: 'def5d18b4bce4a36925fdc429b6d7809',
        appSecret: '79a8fd4f4aeea590206effdbfdce2147'
      }
      getTokenVideo(data).then(res => {
        if (res.code == 200) {
          this.accessToken = res.data.accessToken
        }
      })
    },
    
    initEzuik(url, id, index) {
      console.log('url', url)
      player = new EZUIKit.EZUIKitPlayer({
        autoplay: true,
        id: id,
        accessToken: this.pingfangziaccessToke,
        // url: "ezopen://QWAIUB@open.ys7.com/G11015266/1.live",
        // url: "ezopen://QWAIUB@open.ys7.com/G11015266/1.live",
        url: url,
        template: 'simple', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
        // 视频上方头部控件
        //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
        //plugin: ['talk'],                       // 加载插件，talk-对讲
        // 视频下方底部控件
        // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
        audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
        // openSoundCallBack: data => console.log("开启声音回调", data),
        // closeSoundCallBack: data => console.log("关闭声音回调", data),
        // startSaveCallBack: data => console.log("开始录像回调", data),
        // stopSaveCallBack: data => console.log("录像回调", data),
        // capturePictureCallBack: data => console.log("截图成功回调", data),
        // fullScreenCallBack: data => console.log("全屏回调", data),
        // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
        width: '100%',
        height: '200px'
      })
    }
  },
  beforeDestroy() {
    if (this.timeId) {
      clearInterval(this.timeId)
    }
  },
  mounted() {
    this.getTokenVideo()
    this.getTokenpingfangzi()
  }
}
</script>
<style lang="scss" scoped>
.ezuikit_box {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  .ezui_video {
    position: relative;
  }
}
.video_box {
  width: 600px;
  height: 600px;
  position: relative;
}
.close {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 114px;
  top: 7px;
  z-index: 10000;
  font-size: 40px;
  color: #fff;
  cursor: pointer;
}
.btn{
  width: 400px;
  height: 40px;
  position: absolute;
  left: 800px;
  top: 800px;
  cursor: pointer;
}
.sp_main {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.8);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  .sp_item {
    width: 20%;
    // padding: 0 40px;
    // box-sizing: border-box;
    // background: rgba($color: #000000, $alpha: 0.1);
    // position: relative;
    .sdwd_box {
      position: absolute;
      left: 30px;
      top: 60px;
      z-index: 10000;
      font-size: 40px;
      color: #fff;
      .sd_word {
        font-size: 14px;
        margin-left: 30px;
      }
    }
  }
}
.sp_body{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 100px;
}
</style>
